import { Interface } from '@ethersproject/abi'
import { getMulticallContract } from './contractHelpers'
import { ethers } from 'ethers'

export const multicall = async (abi, calls) => {
  const multi = getMulticallContract()
  const itf = new Interface(abi)

  const totalLength = calls.length
  let index = 0
  let result = []
  while (index < totalLength) {
    const calldata = calls.slice(index, index + 100).map((call) => ({
      target: call.address.toLowerCase(),
      callData: itf.encodeFunctionData(call.name, call.params),
    }))
    const { returnData } = await multi.methods.aggregate(calldata).call('pending')
    result = [...result, ...returnData]
    index += 100
  }

  const res = result.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  return res
}

export const multicallFailSafe = async (abi, calls) => {
  try {
    const multi = getMulticallContract()

    const itf = new ethers.utils.Interface(abi)

    const calldata = calls.map((call) => [call.address.toLowerCase(), true, itf.encodeFunctionData(call.name, call.params)])
    const returnData = await multi.methods.aggregate3(calldata).call('pending')
    const malformedResult = []

    returnData.forEach((v) => {
      malformedResult.push(v[1])
    })

    const res = malformedResult.map((call, i) => {
      if (call == '0x') {
        call =
          '0x000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
      }
      return itf.decodeFunctionResult(calls[i].name, call)
    })
    return res
  } catch (error) {
    throw new Error(error)
  }
}
